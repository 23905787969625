import { create } from 'zustand';
import { UserInfo, FlowType } from '@/@types';
import { captureException } from '@sentry/nextjs';
import { devtools, persist } from 'zustand/middleware';

import { reqSessionToken, reqTokenValidation } from '@/api';
import { useFlowStore } from './flow';
import { getFlowSteps } from '../utils/flow';

interface AuthState {
  otpToken?: string;
  token?: string;
  userInfo?: UserInfo;
  authStatus?: number;
  userConsent?: boolean;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  error: boolean;
  getSessionToken: (otpToken: string) => void;
  validateToken: () => void;
  removeAuth: () => void;
}

export const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        userConsent: false,
        isAuthenticated: false,
        isAuthenticating: false,
        error: false,
        getSessionToken: async (otpToken) => {
          set(() => ({ isAuthenticating: true }));
          try {
            const { token, userInfo, authStatus } = await reqSessionToken(otpToken);
            const flowType = userInfo?.flowType as FlowType;
            const context = flowType.split(':')[0];
            useFlowStore.setState({
              flowType: flowType,
              flowSteps: getFlowSteps(flowType),
              pageContext: context
            });
            set(() => ({
              token,
              otpToken,
              userInfo,
              authStatus,
              isAuthenticated: true,
              isAuthenticating: false,
              userConsent: false
            }));
          } catch (e) {
            console.error(e);
            captureException(e);
            set(() => ({
              token: undefined,
              userInfo: undefined,
              isAuthenticated: false,
              isAuthenticating: false,
              error: true
            }));
          }
        },
        validateToken: async () => {
          set(() => ({ isAuthenticating: true }));
          try {
            const isValid = await reqTokenValidation();
            console.log('isValid', isValid);
            if (isValid) {
              set(() => ({
                isAuthenticated: true,
                isAuthenticating: false
              }));
            } else {
              throw 'Token expired.';
            }
          } catch (e) {
            console.error(e);
            captureException(e);
            set(() => ({
              token: undefined,
              userInfo: undefined,
              isAuthenticated: false,
              isAuthenticating: false,
              error: true
            }));
          }
        },
        removeAuth: () =>
          set(() => ({
            isAuthenticated: false,
            error: false
          }))
      }),
      {
        name: 'auth'
      }
    )
  )
);
