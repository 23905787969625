import React from 'react';
import dynamic from 'next/dynamic';

const NoSSRWrapper = ({ children }: { children: React.ReactElement }) => (
  <React.Fragment>{children}</React.Fragment>
);

export default dynamic(() => Promise.resolve(NoSSRWrapper), {
  ssr: false
});
