import Image, { type ImageProps } from 'next/image';
import styles from './styles.module.css';

export const Component = (props: ImageProps) => {
  const { src, alt, className, ...others } = props;

  return (
    <span className={styles.imageWrapper}>
      <Image src={src} alt={alt} className={className} {...others} />
    </span>
  );
};

export default Component;
