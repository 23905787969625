export const ModalStyles = {
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    boufin: {
      dialog: {
        rounded: '16px 16px 0 0'
      },
      body: {
        padding: '28px'
      },
      footer: {
        padding: '0 28px 32px 28px'
      }
    }
  },
  // The default `size` or `variant` values
  defaultProps: {
    variant: 'boufin'
  }
};
