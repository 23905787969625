import { client } from '@/utils/apiClient';
import { AxiosError } from 'axios';
import { EntityInfo } from '@/@types';
import { enabledEntitiesAdapter } from './adapters';

export const reqEntities = async (): Promise<Array<EntityInfo>> => {
  try {
    const response = await client.get('widget/entities');
    const entityData = enabledEntitiesAdapter(response.data);
    return entityData;
  } catch (error) {
    const axiosError = error as AxiosError;

    if (axiosError.response) {
      const entityErrorResponse = {
        status: axiosError.response.status
      };

      throw entityErrorResponse;
    }

    throw axiosError;
  }
};
