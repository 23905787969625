import Head from 'next/head';
import React from 'react';

type Props = {
  title?: string;
  description?: string;
  children: React.ReactNode;
};

export default function CustomHead({ title = '', description = '', children }: Props) {
  return (
    <>
      <Head>
        <title>{`Boufin Widget | ${title}`}</title>
        <meta name="description" content={description} />
      </Head>
      {children}
    </>
  );
}
