import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { reqAssessment } from '@/api';

interface State {
  assessmentResult?: any;
  assessing: boolean;
  companyName: string;
  companyId: string;
  legalRepresentative: string;
  legalRepresentativeId: string;
  companyEmail: string;
  companyPhone: string;
  error?: boolean;
  clear: () => void;
  setCompanyInfo: (razonSocial: string, rutEmpresa: string) => void;
  setLegalInfo: (representante: string, rutRepresentante: string) => void;
  setCompanyContactInfo: (emailEmpresa: string, telefonoEmpresa: string) => void;
  triggerAssessment: (taskId: string, args: Record<string, any>) => void;
  getAssessmentParams: (keys: string[]) => Record<string, any>;
}

const initialState = {
  assessing: false,
  assessmentResult: undefined,
  companyName: '',
  companyId: '',
  legalRepresentative: '',
  legalRepresentativeId: '',
  companyEmail: '',
  companyPhone: ''
};

export const useAssessmentStore = create<State>()(
  persist(
    devtools((set, get) => ({
      ...initialState,
      clear: () => set(initialState),
      triggerAssessment: async (taskId, args) => {
        if (!get().assessing) {
          set(() => ({ assessing: true }));
          try {
            const assessmentResponse = await reqAssessment(taskId, args);
            set(() => ({
              assessmentResult: assessmentResponse.data,
              assessing: false
            }));
          } catch (e) {
            set(() => ({ assessing: false, error: true, assessmentResult: undefined }));
            console.log(e);
          }
        }
      },
      setCompanyInfo: (companyName, companyId) => {
        set(() => ({
          companyName,
          companyId
        }));
      },
      setLegalInfo: (legalRepresentative, legalRepresentativeId) => {
        set(() => ({
          legalRepresentative,
          legalRepresentativeId
        }));
      },
      setCompanyContactInfo: (companyEmail, companyPhone) => {
        set(() => ({
          companyEmail,
          companyPhone
        }));
      },
      getAssessmentParams: (keys: string[]) => {
        let params: Record<string, any> = {};
        const state = get() as Record<string, any>;
        for (const k in keys) {
          params = {
            ...params,
            [keys[k]]: state[keys[k]]
          };
        }
        return params;
      }
    })),
    {
      name: 'assessment'
    }
  )
);
