import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { hotjar } from 'react-hotjar';
import { ChakraProvider } from '@chakra-ui/react';
import dayjs from 'dayjs';

import AuthProvider from '@/components/AuthProvider';
import Header from '@/components/Header';
import FlowProvider from '@/components/FlowProvider';
import NoSSRWrapper from '@/components/NoSSRWrapper';
import GoogleAnalytics from '@/components/GoogleAnalytics';

import { BoufinTheme } from 'styles/theme';

import 'dayjs/locale/es';
dayjs.locale('es');

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    const hotjarID = process.env.NEXT_PUBLIC_HOTJAR_ID;
    const hotjarVer = process.env.NEXT_PUBLIC_HOTJAR_VERSION;

    if (hotjarID && hotjarVer) {
      hotjar.initialize(parseInt(hotjarID), parseInt(hotjarVer));
    }
  }, []);

  return (
    <NoSSRWrapper>
      <ChakraProvider theme={BoufinTheme}>
        <Header />
        <div id="app-container">
          <GoogleAnalytics />
          <AuthProvider>
            <FlowProvider>
              <Component {...pageProps} />
            </FlowProvider>
          </AuthProvider>
        </div>
      </ChakraProvider>
    </NoSSRWrapper>
  );
}
