import jwt from 'jsonwebtoken';
import { client } from '@/utils/apiClient';
import { UserInfo } from '@/@types';

export const reqSessionToken = async (
  otpToken: string
): Promise<{ token?: string; userInfo?: UserInfo; authStatus: number }> => {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${otpToken}`
    };
    const response = await client.post('widget/auth', {}, { headers });
    const { token } = response.data;

    const tokenDecode = jwt.decode(token) as { data: UserInfo };
    const userInfo = tokenDecode.data;
    return {
      token,
      userInfo,
      authStatus: response.status
    };
  } catch (error: any) {
    return {
      authStatus: error.response?.status
    };
  }
};

export const reqTokenValidation = async (): Promise<boolean> => {
  const response = await client.get('widget/auth/is-valid-token');
  if (response.status === 204) {
    return true;
  }
  return false;
};
