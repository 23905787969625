export const ButtonStyles = {
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    boufin: {
      bg: '#4261FF',
      color: 'white',
      border: 'none',
      width: '100%',
      height: '48px',
      rounded: 'full',
      _hover: {
        bg: '#4261FF',
        boxShadow: '0px 2px 8px 0px rgba(66, 97, 255, 0.80)'
      },
      _active: {
        bg: '#4261FF',
        border: '2px solid rgba(0, 0, 0, 0.10)'
      }
    },
    finish: {
      bg: '#D9DFFF',
      color: '#4261FF',
      border: 'none',
      width: '100%',
      height: '48px',
      rounded: 'full'
    },
    claveunica: {
      bg: '#2E5BA8',
      color: 'white',
      border: 'none',
      width: '100%',
      height: '48px',
      borderRadius: '16px',
      _hover: {
        bg: '#244887'
      },
      _active: {
        bg: '#1E3B6E'
      }
    },
    solid: {
      bg: '#F5F7FE',
      color: 'var(--Black, #3B3857)'
    },
    outline: {
      bg: '#D9DFFF',
      color: '#4261FF',
      border: 'none',
      width: '100%',
      height: '48px',
      rounded: 'full',
      _hover: {
        bg: '#D9DFFF',
        border: '1px solid #4261FF'
      },
      _active: {
        bg: '#D9DFFF',
        border: '2px solid #4261FF'
      }
    },
    disabled: {
      bg: '#E4E5F1',
      color: '#B5B8DA',
      border: '1',
      borderColor: '#CDCFE5',
      width: '100%',
      height: '48px',
      rounded: 'full',
      cursor: 'not-allowed'
    },
    bancoChile: {
      bgGradient: 'linear(to-l, #295EFF, #5583FF)',
      color: 'white',
      border: 'none',
      width: '100%',
      height: '48px',
      rounded: 'full'
    },
    bancoSantander: {
      bg: '#EC0000',
      color: 'white',
      border: 'none',
      width: '100%',
      height: '48px',
      rounded: 'full'
    },
    bancoEstado: {
      bg: '#FF9100',
      color: 'white',
      border: 'none',
      width: '100%',
      height: '48px',
      rounded: 'full'
    },
    bancoScotia: {
      bg: '#D81E05',
      color: 'white',
      border: 'none',
      width: '100%',
      height: '48px',
      rounded: 'full'
    },
    bancoItau: {
      bgGradient: 'linear(to-t, #EE8131, #F3C276)',
      color: 'white',
      border: 'none',
      width: '100%',
      height: '48px',
      rounded: 'full'
    },
    bancoFalabella: {
      bg: '#38A121',
      color: 'white',
      border: 'none',
      width: '100%',
      height: '48px',
      rounded: 'full'
    },
    bancoBice: {
      bg: '#1976D2',
      color: 'white',
      border: 'none',
      width: '100%',
      height: '48px',
      rounded: 'full'
    },
    bancoConsorcio: {
      bg: '#84BD00',
      color: 'white',
      border: 'none',
      width: '100%',
      height: '48px',
      rounded: 'full'
    },
    bancoSecurity: {
      bg: '#6A2E92',
      color: 'white',
      border: 'none',
      width: '100%',
      height: '48px',
      rounded: 'full'
    },
    bancoRipley: {
      bg: '#894590',
      color: 'white',
      border: 'none',
      width: '100%',
      height: '48px',
      rounded: 'full'
    },
    passwordEye: {
      bg: 'white',
      padding: '4px',
      _hover: {
        bg: '#F5F5FA'
      }
    },
    miniAccept: {
      bg: '#4261FF',
      weight: '600',
      fontSize: '16px',
      width: '45%',
      height: '48px',
      rounded: 'full',
      color: 'white'
    },
    miniReject: {
      bg: '#FFE8D5',
      weight: '600',
      fontSize: '16px',
      width: '45%',
      height: '48px',
      rounded: 'full',
      color: '#B71518'
    },
    tycbutton: {
      bg: '#F2F5FC',
      color: '#4261FF',
      border: 'none',
      width: '100%',
      height: '48px',
      rounded: 'full',
      fontSize: '14px',
      fontWeight: '600'
    }
  },
  // The default `size` or `variant` values
  defaultProps: {}
};
