export const syncedAccountsData = [
  {
    entityId: 'banco-chile',
    permissions: [
      {
        action: 'income-report',
        origin: 'Banco Scotiabank',
        enabled: true
      },
      {
        action: 'investment-report',
        origin: 'Banco Scotiabank',
        enabled: true
      }
    ]
  },
  {
    entityId: 'banco-estado',
    permissions: [
      {
        action: 'income-report',
        origin: 'Banco Scotiabank',
        enabled: true
      },
      {
        action: 'tax-folder',
        origin: 'Banco Scotiabank',
        enabled: true
      }
    ]
  }
];

export const permissionHistoryData = [
  {
    date: '2023-08',
    data: [
      {
        entityId: 'banco-estado',
        action: 'tax-folder',
        origin: 'Banco Scotiabank',
        datetime: '2023-08-01 21:36'
      },
      {
        entityId: 'banco-estado',
        action: 'income-report',
        origin: 'Banco Scotiabank',
        datetime: '2023-08-01 21:30'
      }
    ]
  },
  {
    date: '2023-07',
    data: [
      {
        entityId: 'banco-chile',
        action: 'income-report',
        origin: 'Banco Scotiabank',
        datetime: '2023-07-27 13:15'
      },
      {
        entityId: 'banco-chile',
        action: 'investment-report',
        origin: 'Banco Scotiabank',
        datetime: '2023-07-27 13:05'
      }
    ]
  }
];
