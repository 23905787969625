import i18n from 'i18next';
import es from './es.json';
import { initReactI18next } from 'react-i18next';

export const resources = {
  es: {
    translation: es
  }
} as const;

i18n.use(initReactI18next).init({
  lng: 'es',
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  nsSeparator: false,
  resources
});
