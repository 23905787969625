import { client } from '@/utils/apiClient';
import { WidgetServiceResponse, TaskResult, TaskStatus } from '@/@types';
import { AxiosError } from 'axios';

interface ResponseGetTask {
  taskId: string;
  message?: string;
}

interface taskErrorResponse {
  status: number;
  errorMessage: string;
}

interface TaskResponse {
  taskStatus: TaskStatus;
  taskStatusCode: number;
  results: TaskResult;
}

export const reqAssessment = async (taskId: string, args: Record<string, any>): Promise<any> => {
  const response = await client.post('widget/assessment', { taskId, args });

  return response;
};
