import { extendTheme } from '@chakra-ui/react';
import { ButtonStyles as Button } from './components/buttonStyles';
import { InputStyles as Input } from './components/inputStyles';
import { FormLabelStyles as FormLabel } from './components/formLabelStyles';
import { ModalStyles as Modal } from './components/modalStyles';
import { TabsStyles as Tabs } from './components/tabsStyles';
import { CheckboxStyles as Checkbox } from './components/checkboxStyles';
import { LoadingStyles as Loading } from './components/LoadingStyles';

const breakpoints = {
  base: '0px',
  sm: '478px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px'
};

export const BoufinTheme = extendTheme({
  breakpoints,
  styles: {
    global: {
      'html, body': {
        base: {
          padding: 0,
          margin: 0,
          fontFamily: "'Open Sans', sans-serif",
          color: '#79788F',
          fontSize: 16,
          height: '100%'
        },
        sm: {
          padding: 8
        }
      },
      html: {
        background: '#2e3542e6'
      },
      body: {
        background: 'none'
      },
      '#__next': {
        height: '100%'
      },
      '#app-container': {
        base: {
          width: '100%',
          margin: '0 auto',
          height: '100%',
          borderRadius: 0,
          display: 'flex',
          flexDirection: 'column',
          background: 'white',
          boxShadow: '0px 4px 4px 0px #00000040',
          position: 'relative',
          overflow: 'hidden'
        },
        sm: {
          maxWidth: 400,
          minHeight: 600,
          maxHeight: '800',
          borderRadius: 16
        }
      },
      '#app-container nav': {
        padding: '24px',
        paddingBottom: '16px',
        borderBottom: '1px solid var(--gray-300, #E0E2EB)'
      },
      '#app-container main': {
        padding: '24px',
        overflowY: 'auto',
        height: '100%'
      },
      'h1,h2,h3,h4,h5': {
        color: '#3B3857'
      },
      // webkit scrollbar styles
      '::-webkit-scrollbar': {
        width: '6px',
        marginRight: 1
      },
      '::-webkit-scrollbar-track': {
        background: '#F0F0F0'
      },
      '::-webkit-scrollbar-thumb': {
        background: '#CDCDCD'
      },
      '::-webkit-scrollbar-thumb:window-inactive': {
        background: '#CDCDCD'
      },
      '.chakra-checkbox__label': {
        fontSize: '14px !important'
      }
    }
  },
  colors: {
    boufin: '#fff'
  },
  components: {
    Button,
    Input,
    FormLabel,
    Modal,
    Tabs,
    Checkbox,
    Skeleton: Loading
  }
});
