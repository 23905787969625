import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { TaskResult, TaskStatus } from '@/@types';
import { reqTask, reqTaskResult, getTaskErrorResponse } from '@/api';
import { AxiosError } from 'axios';

export enum TaskState {
  Started = 'started',
  Finding = 'finding',
  Finished = 'finished',
  Failed = 'failed'
}

interface State {
  taskId: string | null;
  taskState?: TaskState;
  taskStatus?: TaskStatus;
  taskStatusCode?: number;
  taskResult?: TaskResult;
  status?: number;
  error: boolean;
  errorMessage?: string;
  loading: boolean;
  fetchingResult: boolean;
  wrongLoginCount: number;
  clear: () => void;
  clearErrors: () => void;
  clearTaskResult: () => void;
  triggerTask: (password: string, action: string) => void;
  getTaskResult: (taskId: string) => void;
}

const initialStatus = {
  taskId: null,
  loading: false,
  fetchingResult: false,
  error: false,
  errorMessage: '',
  wrongLoginCount: 0
};

const resetStatus = {
  error: false,
  errorMessage: '',
  status: undefined
};

export const useTaskStore = create<State>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialStatus,
        clear: () => set(initialStatus),
        triggerTask: async (password, action) => {
          console.log('triggerTask');
          set(() => ({ loading: true, error: false }));
          try {
            const { taskId } = await reqTask(password, action);
            set(() => ({
              taskId,
              taskStatus: TaskStatus.Pending,
              taskState: TaskState.Started,
              loading: false
            }));
          } catch (e) {
            const { status, errorMessage } = getTaskErrorResponse(e as AxiosError);
            set(() => ({
              taskId: '',
              status,
              error: true,
              errorMessage,
              loading: false
            }));
          }
        },
        getTaskResult: async (taskId) => {
          console.log('getTaskResult');
          if (get().fetchingResult) {
            return;
          }
          set(() => ({ fetchingResult: true }));
          try {
            const { results, status, taskStatus, taskStatusCode, taskFinished } =
              await reqTaskResult(taskId);
            set((state) => ({
              fetchingResult: false,
              taskResult: results,
              taskStatus,
              taskStatusCode,
              status,
              taskState: taskFinished ? TaskState.Finished : TaskState.Finding,
              wrongLoginCount:
                taskStatus === TaskStatus.WrongLogin
                  ? state.wrongLoginCount + 1
                  : state.wrongLoginCount
            }));
          } catch (e) {
            const { status, errorMessage } = getTaskErrorResponse(e as AxiosError);
            set(() => ({
              taskResult: undefined,
              error: true,
              fetchingResult: false,
              status,
              errorMessage,
              taskState: TaskState.Failed
            }));
          }
        },
        clearTaskResult: () => {
          set(() => ({
            taskId: null,
            taskState: undefined,
            taskStatus: undefined,
            taskStatusCode: undefined,
            taskResult: undefined
          }));
        },
        clearErrors: () => {
          set(resetStatus);
        }
      }),
      {
        name: 'task'
      }
    )
  )
);
