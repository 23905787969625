import { EntityInfo } from '@/@types';

export const enabledEntitiesAdapter = (data: EntityInfo[]) => {
  return data.map((entity) => ({
    name: entity.name,
    id: entity.id,
    enabled: entity.enabled,
    actions: entity.actions
  }));
};
