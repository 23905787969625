import React from 'react';
import { Button, Center } from '@chakra-ui/react';
import Image from '@/components/Image';
import { IconUserX, IconBarrierBlock } from '@tabler/icons-react';
import styles from './styles.module.css';

import FaceEmoji from '@images/fluentui-emoji/face_with_head-bandage_color.svg';
import { useTranslation, Trans } from 'react-i18next';
import { useAuthStore, useFlowStore } from '@/store';

interface ErrorScreenProps {
  mainIcon: any;
  mainMessage: string;
  messages: {
    message: string | JSX.Element;
    icon: JSX.Element;
  }[];
  children?: React.ReactElement | React.ReactElement[];
}

export const ErrorScreen = ({ mainIcon, mainMessage, messages, children }: ErrorScreenProps) => {
  return (
    <>
      <Center w="100%" p={4} display="flex" flexDirection="column" flex={1}>
        <div className={styles.errorIconContainer}>
          <div className={styles.iconContainer}>
            <Image src={mainIcon} width={64} height={64} alt="" />
          </div>
        </div>
        <p className={styles.mainProgressMessage}>{mainMessage}</p>
        <div className={styles.errorMessage}>
          <div className={styles.messageContainer}>
            {messages?.map(({ message, icon }, i) => (
              <div className={styles.errorMessage} key={i}>
                <span>{icon}</span> <p>{message}</p>
              </div>
            ))}
          </div>
        </div>
      </Center>
      <Center w="100%" flexDirection="column" gap={4} p={'24px'}>
        {children}
      </Center>
    </>
  );
};

export const ErrorScreenInformation = () => {
  const { t } = useTranslation();
  const { authStatus } = useAuthStore();
  const { error } = useFlowStore();

  if (authStatus === 401 || authStatus === 422 || authStatus === undefined) {
    return (
      <ErrorScreen
        mainIcon={FaceEmoji}
        mainMessage={t('errorPage.authFailedTitle')}
        messages={[
          {
            icon: <IconUserX />,
            message: <Trans i18nKey="errorPage.authFailedMessage" />
          }
        ]}
      ></ErrorScreen>
    );
  } else if (authStatus === 500 || error) {
    return (
      <ErrorScreen
        mainIcon={FaceEmoji}
        mainMessage={t('errorPage.title')}
        messages={[
          {
            icon: <IconBarrierBlock />,
            message: <Trans i18nKey="errorPage.message" />
          }
        ]}
      >
        <Button onClick={() => window.location.reload()} variant={'boufin'} mb={'5px'}>
          {t('errorPage.reload')}
        </Button>
      </ErrorScreen>
    );
  }
  return <></>;
};
