export const FormLabelStyles = {
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    boufin: {
      fontSize: '16px',
      color: '#726F86'
    }
  },
  // The default `size` or `variant` values
  defaultProps: {
    variant: 'boufin'
  }
};
