import Head from 'next/head';

const Header = () => {
  return (
    <Head>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="robots" content="all" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://widget.boufin.com" />
    </Head>
  );
};

export default Header;
