import { FlowType, FlowStepComponent, AssessingFlowStep, ProcessingFlowStep } from '@/@types';
import { getFlowConfig } from './index';

export const getAssessmentStepConfig = (flowType: FlowType): Partial<AssessingFlowStep> => {
  const flowConfig = getFlowConfig(flowType);
  const assessmentStep = flowConfig.steps.find((s) => s.page === FlowStepComponent.ASSESSING);
  if (!assessmentStep) {
    throw new Error('No assessment step found');
  }
  const assessmentStepConfig = { ...assessmentStep } as Partial<AssessingFlowStep>;
  delete assessmentStepConfig['page'];
  return assessmentStepConfig;
};

export const getProcessingStepConfig = (FlowType: FlowType): Partial<ProcessingFlowStep> => {
  const flowConfig = getFlowConfig(FlowType);
  const processingStep = flowConfig.steps.find((s) => s.page === FlowStepComponent.PROCESSING);
  if (!processingStep) {
    throw new Error('No processing step found');
  }
  const processingStepConfig = { ...processingStep } as Partial<ProcessingFlowStep>;
  delete processingStepConfig['page'];
  return processingStepConfig;
};

export const getAssessmentArgs = (flowType: FlowType) => {
  const assessmentStepConfig = getAssessmentStepConfig(flowType);
  if (!assessmentStepConfig['assessmentArgs']) {
    throw new Error('No assessment args found');
  }
  return assessmentStepConfig['assessmentArgs'];
};

export * from './config';
