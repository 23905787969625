import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  checkboxAnatomy.keys
);

const baseStyle = definePartsStyle({
  label: {
    color: 'var(--Black, #3B3857)',
    fontWeight: 600
  },
  control: {
    width: '18px',
    height: '18px'
  }
});

export const CheckboxStyles = defineMultiStyleConfig({ baseStyle });
