import {
  FlowTypes,
  FlowType,
  FlowStep,
  FlowStepComponent,
  EntityId,
  EntityType,
  ProcessingStep
} from '@/@types';
import '@/utils/i18n/config';
import BriefcaseSvg from '@images/fluentui-emoji/briefcase_color.svg';
import LightBulbSvg from '@images/fluentui-emoji/light_bulb_color.svg';
import ShowerSvg from '@images/fluentui-emoji/shower_color.svg';
import ReceiptSvg from '@images/fluentui-emoji/receipt_color.svg';
import EMailSvg from '@images/fluentui-emoji/e-mail_color.svg';
import MoneyWithWingsSvg from '@images/fluentui-emoji/money_with_wings_color.svg';
import SeedlingSvg from '@images/fluentui-emoji/seedling_color.svg';
import TearOffCalendarSvg from '@images/fluentui-emoji/tear-off_calendar_color.svg';
import WomanRaisingHandSvg from '@images/fluentui-emoji/woman_raising_hand_color_medium.svg';
import BankSvg from '@images/fluentui-emoji/bank_color.svg';
import CreditCardSvg from '@images/fluentui-emoji/credit_card_color.svg';
import HouseSvg from '@images/fluentui-emoji/house_color.svg';
import DollarSvg from '@images/fluentui-emoji/dollar_banknote_color.svg';
import ScrollSvg from '@images/fluentui-emoji/scroll_color.svg';
import BookmarkSvg from '@images/fluentui-emoji/bookmark_tabs_color.svg';
import { titleIcons } from '../iconshelper';

type FlowConfig = {
  [key: string]: {
    requiredActions?: string[];
    actionType?: string;
    allowedEntityTypes: EntityType[];
    enabledEntities: EntityId[];
    defaultEntity?: EntityId;
    messages?: {
      title: string;
      text: string;
      icon?: JSX.Element;
    }[];
    steps: FlowStep[];
  };
};

const flowConfig: FlowConfig = {
  [FlowTypes.EXTRACTION_UPDATE_SALARY]: {
    requiredActions: ['consolidate'],
    allowedEntityTypes: [EntityType.GOVERMENT],
    defaultEntity: EntityId.AFC,
    messages: [
      {
        title: 'availableInformation.personalInfoTitle',
        text: 'availableInformation.personalInfoText',
        icon: titleIcons.personalInfo
      },
      {
        title: 'availableInformation.personalSalaryTitle',
        text: 'availableInformation.personalSalaryText',
        icon: titleIcons.salaryInfo
      },
      {
        title: 'availableInformation.personalInfoTributaryTitle',
        text: 'availableInformation.personalInfoTributaryText',
        icon: titleIcons.tributary
      },
      {
        title: 'availableInformation.personalInfoDebtTitle',
        text: 'availableInformation.personalInfoDebtText',
        icon: titleIcons.debt
      },
      {
        title: 'availableInformation.personalWorkingInfoTitle',
        text: 'availableInformation.personalWorkingInfoText',
        icon: titleIcons.consolidate
      }
    ],
    enabledEntities: [EntityId.AFC],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'consolidate'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: ReceiptSvg,
            messageKey: 'findingMessage1'
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 35,
            image: ReceiptSvg,
            messageKey: 'findingMessage2'
          },
          {
            step: ProcessingStep.Finding,
            image: ReceiptSvg,
            messageKey: 'findingMessage3'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.EXTRACTION_INVESTMENT]: {
    requiredActions: ['investment-report'],
    allowedEntityTypes: [EntityType.BANK],
    enabledEntities: [EntityId.ESTADO, EntityId.CHILE, EntityId.SCOTIABANK, EntityId.SANTANDER],
    messages: [
      {
        title: 'availableInformation.personalInvestmentsTitle',
        text: 'availableInformation.personalInvestmentsText',
        icon: titleIcons.investment
      }
    ],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.ENTITIES
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'investment-report'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: TearOffCalendarSvg,
            messageKey: 'findingMessage1'
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 35,
            image: SeedlingSvg,
            messageKey: 'findingMessage2'
          },
          {
            step: ProcessingStep.Finding,
            image: MoneyWithWingsSvg,
            messageKey: 'findingMessage3'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.EXTRACTION_BILLS]: {
    requiredActions: ['bill'],
    allowedEntityTypes: [EntityType.SERVICE, EntityType.BANK],
    enabledEntities: [EntityId.SERVIPAG, EntityId.ESTADO],
    messages: [
      {
        title: 'availableInformation.personalBillsTitle',
        text: 'availableInformation.personalBillsText',
        icon: titleIcons.bills
      }
    ],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.ENTITIES
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'bill'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: LightBulbSvg,
            messageKey: 'findingMessage1'
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 35,
            image: ShowerSvg,
            messageKey: 'findingMessage2'
          },
          {
            step: ProcessingStep.Finding,
            image: ReceiptSvg,
            messageKey: 'findingMessage3'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.EXTRACTION_TEF]: {
    requiredActions: ['tef'],
    allowedEntityTypes: [EntityType.BANK],
    enabledEntities: [
      EntityId.ESTADO,
      EntityId.BCI,
      EntityId.CHILE,
      EntityId.SANTANDER,
      EntityId.FALABELLA,
      EntityId.SCOTIABANK,
      EntityId.ITAU,
      EntityId.RIPLEY,
      EntityId.SECURITY,
      EntityId.CONSORCIO
    ],
    messages: [
      {
        title: 'availableInformation.personalRecipientsTitle',
        text: 'availableInformation.personalRecipientsText',
        icon: titleIcons.tefIcon
      }
    ],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.ENTITIES
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'tef'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: WomanRaisingHandSvg,
            messageKey: 'findingMessage1'
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 35,
            image: BankSvg,
            messageKey: 'findingMessage2'
          },
          {
            step: ProcessingStep.Finding,
            image: EMailSvg,
            messageKey: 'findingMessage3'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.EXTRACTION_MOVEMENT]: {
    requiredActions: ['movement'],
    allowedEntityTypes: [EntityType.BANK],
    enabledEntities: [
      EntityId.ESTADO,
      EntityId.BCI,
      EntityId.CHILE,
      EntityId.SANTANDER,
      EntityId.FALABELLA,
      EntityId.SCOTIABANK,
      EntityId.ITAU,
      EntityId.RIPLEY,
      EntityId.SECURITY,
      EntityId.CONSORCIO
    ],
    messages: [
      {
        title: 'availableInformation.personalMovementsTitle',
        text: 'availableInformation.personalMovementsText',
        icon: titleIcons.bankingInfo
      }
    ],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.ENTITIES
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'movement'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: ReceiptSvg,
            messageKey: 'findingMessage1'
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 35,
            image: ReceiptSvg,
            messageKey: 'findingMessage2'
          },
          {
            step: ProcessingStep.Finding,
            image: ReceiptSvg,
            messageKey: 'findingMessage3'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.EXTRACTION_INCOME_REPORT]: {
    requiredActions: ['income-report'],
    allowedEntityTypes: [EntityType.BANK],
    enabledEntities: [
      EntityId.ESTADO,
      EntityId.BCI,
      EntityId.CHILE,
      EntityId.SANTANDER,
      EntityId.FALABELLA,
      EntityId.SCOTIABANK,
      EntityId.ITAU,
      EntityId.RIPLEY,
      EntityId.SECURITY,
      EntityId.CONSORCIO
    ],
    messages: [
      {
        title: 'availableInformation.personalSalaryTitle',
        text: 'availableInformation.personalSalaryText',
        icon: titleIcons.salaryInfo
      }
    ],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.ENTITIES
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'income-report'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: ReceiptSvg,
            messageKey: 'findingMessage1'
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 35,
            image: ReceiptSvg,
            messageKey: 'findingMessage2'
          },
          {
            step: ProcessingStep.Finding,
            image: ReceiptSvg,
            messageKey: 'findingMessage3'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.EXTRACTION_FINANCIAL_REPORT]: {
    requiredActions: ['financial-report'],
    allowedEntityTypes: [EntityType.BANK],
    enabledEntities: [
      EntityId.ESTADO,
      EntityId.BCI,
      EntityId.CHILE,
      EntityId.SANTANDER,
      EntityId.FALABELLA,
      EntityId.SCOTIABANK,
      EntityId.ITAU,
      EntityId.RIPLEY,
      EntityId.SECURITY,
      EntityId.CONSORCIO
    ],
    messages: [
      {
        title: 'availableInformation.financialReportTitle',
        text: 'availableInformation.financialReportText',
        icon: titleIcons.bankingInfo
      }
    ],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.ENTITIES
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'financial-report'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: ReceiptSvg,
            messageKey: 'findingMessage1'
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 35,
            image: CreditCardSvg,
            messageKey: 'findingMessage2'
          },
          {
            step: ProcessingStep.Finding,
            image: ReceiptSvg,
            messageKey: 'findingMessage3'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.EXTRACTION_CONSOLIDATE]: {
    requiredActions: ['consolidate'],
    allowedEntityTypes: [EntityType.GOVERMENT],
    defaultEntity: EntityId.AFC,
    messages: [
      {
        title: 'availableInformation.personalWorkingInfoTitle',
        text: 'availableInformation.personalWorkingInfoText',
        icon: titleIcons.consolidate
      }
    ],
    enabledEntities: [EntityId.AFC],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'consolidate'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: ReceiptSvg,
            messageKey: 'findingMessage1'
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 35,
            image: ReceiptSvg,
            messageKey: 'findingMessage2'
          },
          {
            step: ProcessingStep.Finding,
            image: ReceiptSvg,
            messageKey: 'findingMessage3'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.EXTRACTION_DEBT]: {
    requiredActions: ['debt'],
    allowedEntityTypes: [EntityType.GOVERMENT],
    defaultEntity: EntityId.CMF,
    messages: [
      {
        title: 'availableInformation.personalSalaryTitle',
        text: 'availableInformation.personalSalaryText',
        icon: titleIcons.salaryInfo
      },
      {
        title: 'availableInformation.personalInfoTributaryTitle',
        text: 'availableInformation.personalInfoTributaryText',
        icon: titleIcons.tributary
      },
      {
        title: 'availableInformation.personalInfoDebtTitle',
        text: 'availableInformation.personalInfoDebtText',
        icon: titleIcons.debt
      }
    ],
    enabledEntities: [EntityId.CMF],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'debt'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: MoneyWithWingsSvg,
            messageKey: 'findingMessage1'
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 35,
            image: MoneyWithWingsSvg,
            messageKey: 'findingMessage2'
          },
          {
            step: ProcessingStep.Finding,
            image: MoneyWithWingsSvg,
            messageKey: 'findingMessage3'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.EXTRACTION_PROPERTY_TAX]: {
    requiredActions: ['property-tax'],
    allowedEntityTypes: [EntityType.GOVERMENT],
    defaultEntity: EntityId.SII,
    enabledEntities: [EntityId.SII],
    messages: [
      {
        title: 'availableInformation.personalContributionsTitle',
        text: 'availableInformation.personalContributionsText',
        icon: titleIcons.tributary
      }
    ],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'property-tax'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: HouseSvg,
            messageKey: 'findingMessage1'
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 35,
            image: MoneyWithWingsSvg,
            messageKey: 'findingMessage2'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.EXTRACTION_PROPERTY_TAX_CLAVE_UNICA]: {
    requiredActions: ['property-tax-clave-unica'],
    allowedEntityTypes: [EntityType.GOVERMENT],
    defaultEntity: EntityId.SII,
    enabledEntities: [EntityId.SII],
    messages: [
      {
        title: 'availableInformation.personalContributionsTitle',
        text: 'availableInformation.personalContributionsText',
        icon: titleIcons.tributary
      }
    ],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'property-tax-clave-unica'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: HouseSvg,
            messageKey: 'findingMessage1'
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 35,
            image: MoneyWithWingsSvg,
            messageKey: 'findingMessage2'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.EXTRACTION_TAX_FOLDER]: {
    requiredActions: ['tax-folder'],
    allowedEntityTypes: [EntityType.GOVERMENT],
    defaultEntity: EntityId.SII,
    enabledEntities: [EntityId.SII],
    messages: [
      {
        title: 'availableInformation.personalInfoTributaryTitle',
        text: 'availableInformation.personalInfoTributaryText',
        icon: titleIcons.tributary
      }
    ],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'tax-folder'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: BriefcaseSvg,
            messageKey: 'findingMessage1'
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 35,
            image: ReceiptSvg,
            messageKey: 'findingMessage2'
          },
          {
            step: ProcessingStep.Finding,
            image: MoneyWithWingsSvg,
            messageKey: 'findingMessage3'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.EXTRACTION_TAX_FOLDER_PDF]: {
    requiredActions: ['tax-folder-pdf'],
    allowedEntityTypes: [EntityType.GOVERMENT],
    defaultEntity: EntityId.SII,
    enabledEntities: [EntityId.SII],
    messages: [
      {
        title: 'availableInformation.personalInfoTributaryTitle',
        text: 'availableInformation.personalInfoTributaryText',
        icon: titleIcons.tributary
      }
    ],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'tax-folder-pdf'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: BriefcaseSvg,
            messageKey: 'findingMessage1'
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 35,
            image: ReceiptSvg,
            messageKey: 'findingMessage2'
          },
          {
            step: ProcessingStep.Finding,
            image: MoneyWithWingsSvg,
            messageKey: 'findingMessage3'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.EXTRACTION_TAX_FOLDER_CLAVE_UNICA]: {
    requiredActions: ['tax-folder-clave-unica'],
    allowedEntityTypes: [EntityType.GOVERMENT],
    defaultEntity: EntityId.SII,
    enabledEntities: [EntityId.SII],
    messages: [
      {
        title: 'availableInformation.personalInfoTributaryTitle',
        text: 'availableInformation.personalInfoTributaryText',
        icon: titleIcons.tributary
      }
    ],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'tax-folder-clave-unica'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: BriefcaseSvg,
            messageKey: 'findingMessage1'
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 35,
            image: ReceiptSvg,
            messageKey: 'findingMessage2'
          },
          {
            step: ProcessingStep.Finding,
            image: MoneyWithWingsSvg,
            messageKey: 'findingMessage3'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.EXTRACTION_TAX_FOLDER_PDF_CLAVE_UNICA]: {
    requiredActions: ['tax-folder-pdf-clave-unica'],
    allowedEntityTypes: [EntityType.GOVERMENT],
    defaultEntity: EntityId.SII,
    enabledEntities: [EntityId.SII],
    messages: [
      {
        title: 'availableInformation.personalInfoTributaryTitle',
        text: 'availableInformation.personalInfoTributaryText',
        icon: titleIcons.tributary
      }
    ],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'tax-folder-pdf-clave-unica'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: BriefcaseSvg,
            messageKey: 'findingMessage1'
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 35,
            image: ReceiptSvg,
            messageKey: 'findingMessage2'
          },
          {
            step: ProcessingStep.Finding,
            image: MoneyWithWingsSvg,
            messageKey: 'findingMessage3'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.EXTRACTION_INSURANCE]: {
    requiredActions: ['insurance'],
    allowedEntityTypes: [EntityType.GOVERMENT],
    defaultEntity: EntityId.CMF,
    messages: [
      {
        title: 'availableInformation.insuranceTitle',
        text: 'availableInformation.insuranceText',
        icon: titleIcons.insurance
      }
    ],
    enabledEntities: [EntityId.CMF],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'insurance'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: MoneyWithWingsSvg,
            messageKey: 'findingMessage1'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.EXTRACTION_CONSUMER_LOAN]: {
    requiredActions: ['consumer-loan'],
    allowedEntityTypes: [EntityType.BANK],
    enabledEntities: [EntityId.BCI, EntityId.CHILE, EntityId.SCOTIABANK],
    messages: [
      {
        title: 'availableInformation.personalConsumerTitle',
        text: 'availableInformation.personalConsumerText',
        icon: titleIcons.consumerLoan
      }
    ],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.ENTITIES
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'consumer-loan'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: BankSvg,
            messageKey: 'findingMessage1'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.ASSESSMENT_NIUBANK_FLOW1]: {
    requiredActions: ['tax-folder'],
    allowedEntityTypes: [EntityType.GOVERMENT],
    defaultEntity: EntityId.SII,
    enabledEntities: [EntityId.SII],
    steps: [
      {
        page: FlowStepComponent.COMPANY_INFO
      },
      {
        page: FlowStepComponent.LEGAL_INFO
      },
      {
        page: FlowStepComponent.COMPANY_CONTACT_INFO
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'tax-folder'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: ScrollSvg,
            messageKey: 'findingMessage1'
          },
          {
            step: ProcessingStep.Finding,
            image: DollarSvg,
            messageKey: 'findingMessage2'
          }
        ]
      },
      {
        page: FlowStepComponent.ASSESSING,
        assessmentArgs: [
          'companyName',
          'companyId',
          'legalRepresentative',
          'legalRepresentativeId',
          'companyEmail',
          'companyPhone'
        ],
        messageKey: 'assessingMessage',
        image: BookmarkSvg
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.EXTRACTION_CREDIT_CARD_STATEMENT]: {
    requiredActions: ['credit-card-statement'],
    allowedEntityTypes: [EntityType.BANK],
    enabledEntities: [
      EntityId.ESTADO,
      EntityId.BCI,
      EntityId.CHILE,
      EntityId.SANTANDER,
      EntityId.FALABELLA,
      EntityId.SCOTIABANK,
      EntityId.ITAU,
      EntityId.RIPLEY,
      EntityId.SECURITY,
      EntityId.CONSORCIO
    ],
    messages: [
      {
        title: 'availableInformation.personalBankingInfoTitle',
        text: 'availableInformation.personalBankingCreditCardsData',
        icon: titleIcons.bankingInfo
      }
    ],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.ENTITIES
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'credit-card-statement'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: CreditCardSvg,
            messageKey: 'findingMessage1'
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 35,
            image: CreditCardSvg,
            messageKey: 'findingMessage2'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  },
  [FlowTypes.EXTRACTION_TAX_REPORT]: {
    requiredActions: ['tax-report'],
    allowedEntityTypes: [EntityType.GOVERMENT],
    defaultEntity: EntityId.SUNAT,
    enabledEntities: [EntityId.SUNAT],
    messages: [
      {
        title: 'availableInformation.personalInfoTaxReportTitle',
        text: 'availableInformation.personalInfoTaxReportText',
        icon: titleIcons.tributary
      }
    ],
    steps: [
      {
        page: FlowStepComponent.HOME
      },
      {
        page: FlowStepComponent.CREDENTIALS,
        actionType: 'tax-report'
      },
      {
        page: FlowStepComponent.PROCESSING,
        processingSteps: [
          {
            step: ProcessingStep.Connecting,
            nextStepPct: 15
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 25,
            image: BriefcaseSvg,
            messageKey: 'findingMessage1'
          },
          {
            step: ProcessingStep.Finding,
            nextStepPct: 35,
            image: ReceiptSvg,
            messageKey: 'findingMessage2'
          },
          {
            step: ProcessingStep.Finding,
            image: MoneyWithWingsSvg,
            messageKey: 'findingMessage3'
          }
        ]
      },
      {
        page: FlowStepComponent.SUMMARY
      }
    ]
  }
};

export const getFlowConfig = (flowType: FlowType) => {
  return flowConfig[flowType];
};

export const getFlowSteps = (flowType: FlowType) => {
  return getFlowConfig(flowType).steps.map((s) => s.page);
};
