import { create } from 'zustand';
import dayjs from 'dayjs';
import { devtools, persist } from 'zustand/middleware';

import { reqCheckUserEntityStatus, getFlowStepsFromConfig } from '@/api';
import { EntityConfig, FlowStep, FlowType } from '@/@types';

import { useSettingsStore } from './settings';
import { getFlowConfig } from '../utils/flow';
import { getAllowedEntities } from '../api/flow';

interface FlowState {
  actionType?: string;
  currentStep: number;
  flowSteps: string[];
  flowType?: FlowType;
  entityId: string;
  entityName: string;
  entities?: EntityConfig[];
  selectedEntity?: EntityConfig;
  settingsMode: boolean;
  loading: boolean;
  dataLoaded: boolean;
  error: boolean;
  checkingLockedEntity: boolean;
  previousFlowIndex: number;
  lockedEntities: {
    [key: string]: {
      retryAfter: string;
    };
  };

  pageContext: string;
  prevContextStack: {
    name: string;
    step: number;
  }[];

  displayJsonResult: boolean;
  clear: () => void;
  getCurrentFlowStep: () => [string, string];
  getCurrentFlowStepObject: () => FlowStep;
  goPrevStep: () => void;
  goNextStep: () => void;
  goToStep: (step: string) => void;
  getEntityList: () => void;
  loadFlowSteps: () => void;
  checkUserEntityStatus: (entityId: string) => void;
  getCurrentContextSteps: () => string[];
  pushContext: (newContext: string) => void;
}

const initialState = {
  currentStep: 0,
  entityId: 'niubank',
  entityName: 'NiuBank',
  selectedEntityName: '',
  flowSteps: ['home'],
  loading: false,
  dataLoaded: false,
  error: false,
  checkingLockedEntity: false,
  lockedEntities: {},
  displayJsonResult: true,
  settingsMode: false,
  previousFlowIndex: 0,
  pageContext: 'extraction',
  prevContextStack: []
};
export const useFlowStore = create<FlowState>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        clear: () => set(initialState),
        getCurrentFlowStep: () => {
          switch (get().pageContext) {
            case 'extraction':
              return [get().pageContext, get().flowSteps[get().currentStep]];
            case 'assessment':
              return [get().pageContext, get().flowSteps[get().currentStep]];
            case 'settings':
              return [
                get().pageContext,
                useSettingsStore.getState().settingSteps[get().currentStep]
              ];
            default:
              return ['', ''];
          }
        },
        getCurrentFlowStepObject: () => {
          const steps = getFlowConfig(get().flowType as FlowType).steps;
          return steps[get().currentStep];
        },
        goPrevStep: () =>
          set((state) => {
            const prevStep = state.currentStep - 1;
            if (prevStep < 0 && state.prevContextStack.length > 0) {
              const newPrevContextStack = [...state.prevContextStack];
              const prevContext = newPrevContextStack.pop();
              return {
                prevContextStack: newPrevContextStack,
                pageContext: prevContext?.name,
                currentStep: prevContext?.step
              };
            } else if (prevStep >= 0) {
              return { currentStep: prevStep };
            }
            return { currentStep: state.currentStep };
          }),
        goNextStep: () =>
          set((state) => {
            const contextSteps = state.getCurrentContextSteps();
            if (state.currentStep < contextSteps.length) {
              return { currentStep: state.currentStep + 1 };
            }
            return { currentStep: state.currentStep };
          }),
        goToStep: (step) =>
          set((state) => {
            const contextSteps = state.getCurrentContextSteps();
            const stepIndex = contextSteps.findIndex((s) => s === step);
            if (stepIndex >= 0) {
              return { currentStep: stepIndex };
            }
            return { currentStep: 0 };
          }),
        getCurrentContextSteps: () => {
          switch (get().pageContext) {
            case 'extraction':
              return get().flowSteps;
            case 'assessment':
              return get().flowSteps;
            case 'settings':
              return useSettingsStore.getState().settingSteps;
            default:
              return [];
          }
        },
        pushContext: (newContext) => {
          if (get().pageContext !== newContext) {
            set((state) => {
              const newContextStack = [...state.prevContextStack];
              newContextStack.push({
                name: state.pageContext,
                step: state.currentStep
              });
              return {
                ...state,
                pageContext: newContext,
                currentStep: 0,
                prevContextStack: newContextStack
              };
            });
          }
        },
        setSelectedEntity: (entity: EntityConfig) => {
          set(() => ({
            selectedEntity: entity,
            selectedEntityName: entity.name
          }));
        },
        loadFlowSteps: () => {
          const flowSteps = getFlowStepsFromConfig(get().flowType as FlowType);
          set(() => ({
            flowSteps
          }));
        },
        getEntityList: async () => {
          set(() => ({ loading: true }));
          try {
            const { entities, defaultEntity } = await getAllowedEntities(
              get().flowType as FlowType
            );
            set(() => ({
              entities,
              defaultEntity,
              dataLoaded: true,
              loading: false,
              selectedEntity: defaultEntity || undefined
            }));
          } catch (e) {
            console.log('error getEntityList', e);
            set(() => ({
              loading: false,
              error: true
            }));
          }
        },
        checkUserEntityStatus: async (entityId) => {
          console.log('checkUserEntityStatus');
          set(() => ({ checkingLockedEntity: true }));
          // check if entity is already marked as locked and verify if it is still locked
          if (get().lockedEntities[entityId]) {
            const retryTS = get().lockedEntities[entityId].retryAfter;
            if (dayjs().isBefore(retryTS)) {
              set(() => ({ checkingLockedEntity: false }));
              return;
            }
          }
          try {
            const result = await reqCheckUserEntityStatus(entityId);
            if (result instanceof Error) {
              console.error(result.message);
            } else {
              const { enabled, retryAfter } = result;
              if (!enabled) {
                set((state) => ({
                  lockedEntities: {
                    ...state.lockedEntities,
                    [entityId]: { retryAfter }
                  }
                }));
              } else if (get().lockedEntities[entityId]) {
                const newLockedEntities = { ...get().lockedEntities };
                delete newLockedEntities[entityId];
                set(() => ({ lockedEntities: newLockedEntities }));
              }
              set(() => ({ checkingLockedEntity: false }));
            }
          } catch (e) {
            set(() => ({
              checkingLockedEntity: false,
              error: true
            }));
          }
        }
      }),
      {
        name: 'flow'
      }
    )
  )
);
