import axios from 'axios';
import { useAuthStore } from '@/store';
import { captureMessage, captureException } from '@sentry/nextjs';
import axiosRetry from 'axios-retry';

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;

if (!BASE_URL) {
  const msg = 'BASE_URL is not defined.';
  console.error(msg);
  captureMessage(msg);
}

export const client = axios.create({
  baseURL: `${BASE_URL}api/v1/`,
  headers: {
    'Content-Type': 'application/json'
  }
});

client.interceptors.request.use(
  function (config) {
    axiosRetry(client, {
      retries: 3,
      retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount * 2000;
      },
      retryCondition: (error) => {
        if (error.response?.status != undefined && error.response?.status >= 500) {
          return true;
        }
        return false;
      }
    });
    if (config.url === 'widget/auth') {
      return config;
    }
    const { token } = useAuthStore.getState();
    config.headers!['Authorization'] = `Bearer ${token}`;
    return config;
  },
  function (error) {
    captureException(error);
    return Promise.reject(error);
  }
);
