import { Box, Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react';

interface Props {
  showHeader?: boolean;
  showFooter?: boolean;
  smallContent?: boolean;
  isFading?: boolean;
}

export const Loading = ({
  showHeader = true,
  showFooter = true,
  smallContent = false,
  isFading = true
}: Props) => {
  const options = {
    speed: isFading ? 0.8 : 0,
    startColor: '',
    endColor: '#F5F7FE'
  };

  return (
    <>
      {showHeader && (
        <Box px="6" pt="6" pb="5" borderBottom="solid 1px #E0E2EB">
          <Box display="flex" justifyContent="space-between" alignItems="center" mb="3">
            <SkeletonCircle size="10" {...options} />
            <SkeletonCircle size="12" {...options} />
            <SkeletonCircle size="10" {...options} />
          </Box>
          <SkeletonText noOfLines={1} spacing="4" skeletonHeight="4" {...options} />
        </Box>
      )}
      <Box px="6" pt="4" pb="4" display="flex" flex={1} flexDirection="column" flexBasis={'fit'}>
        <SkeletonText noOfLines={1} spacing="4" skeletonHeight="4" w={'60%'} mb="3" {...options} />
        <Skeleton variant="boufin" borderRadius="xl" height="24" mb="5" {...options} />
        {!smallContent && (
          <>
            <SkeletonText
              noOfLines={1}
              spacing="4"
              skeletonHeight="4"
              w={'60%'}
              mb="3"
              {...options}
            />
            <Skeleton variant="boufin" borderRadius="xl" height="48" {...options} />
          </>
        )}
      </Box>
      {showFooter && (
        <Box padding={'16px 24px 24px 24px'} borderTop="solid 1px #E0E3EB">
          <SkeletonText
            noOfLines={1}
            spacing="1"
            skeletonHeight="4"
            w={'80%'}
            mb="3"
            {...options}
          />
          <Skeleton variant="boufin" borderRadius="full" height="10" {...options} />
        </Box>
      )}
    </>
  );
};

export default Loading;
