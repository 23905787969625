import { FlowType } from '../../@types';
import { reqEntities } from '@/api/entities';
import { getFlowConfig } from '../flow';
import { entities } from './config';
import { ErrorScreenInformation } from '@/components/ErrorScreen';

export const getEnabledEntities = async (flowType: FlowType) => {
  try {
    const dynamicData = await reqEntities();
    const updatedEntities = entities.map((staticEntity) => {
      const dynamicEntity = dynamicData.find((dynamic) => dynamic.id === staticEntity.id);
      if (dynamicEntity) {
        const { requiredActions } = getFlowConfig(flowType);
        const matchingActionType = dynamicEntity.actions.find((action) =>
          requiredActions?.includes(action.actionType as string)
        );
        return {
          ...staticEntity,
          name: dynamicEntity.name,
          active: !!matchingActionType
        };
      } else {
        return staticEntity;
      }
    });
    return updatedEntities;
  } catch {
    return <ErrorScreenInformation />;
  }
};
