export const GA_TRACKING_ID = `${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`;

export const config = () => {
  window.gtag('config', GA_TRACKING_ID, {
    send_page_view: false,
    page_location: window.location,
    user_properties: {}
  });
};

/**
 * This function is used to track page views.
 * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 * */
export const pageView = (url: URL) => {
  window.gtag('event', 'page_view', {
    page_location: url,
    page_title: window.document.title
  });
};

/**
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 * If you want to track events, you can use the gtag.js event method.
 * */
export const event = (
  action: Gtag.EventNames,
  { event_category, event_label, value }: Gtag.EventParams
) => {
  window.gtag('event', action, {
    event_category,
    event_label,
    value
  });
};
