import { cssVar } from '@chakra-ui/react';

const $startColor = cssVar('skeleton-start-color');
const $endColor = cssVar('skeleton-end-color');

export const LoadingStyles = {
  baseStyle: {},
  variants: {
    boufin: {
      _light: {
        [$startColor.variable]: '#F5F7FE',
        [$endColor.variable]: '#E0E2EB'
      }
    }
  },
  defaultProps: {
    variant: 'boufin'
  }
};
