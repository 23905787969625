export enum TaskStatus {
  WrongLogin = 'wrong_login',
  Locked = 'locked',
  Error = 'error',
  Success = 'success',
  Pending = 'pending',
  Running = 'running'
}

export interface WidgetServiceResponse {
  isSuccess: boolean;
  status: number;
  taskStatus?: TaskStatus;
  taskStatusCode?: number;
  message?: string;
  taskId?: string;
  results?: TaskResult | undefined;
  taskFinished?: boolean;
  [key: string]: unknown;
}

export interface Token extends WidgetServiceResponse {
  token?: string;
  userInfo?: UserInfo;
}

export interface AuthState {
  token?: string;
  userInfo?: UserInfo;
  isAuthenticated: boolean;
}

export interface TaskResult {
  entityId: string;
  username?: string;
  data: Record<string, unknown> | [Record<string, unknown>];
}

export interface UserInfo {
  name: string;
  username: string;
  action: string;
  flowType: FlowType;
}

export enum AssessmentFlowTypes {
  ASSESSMENT_NIUBANK_FLOW1 = 'assessment:niubank-flow1'
}

export enum ExtractionFlowType {
  EXTRACTION_UPDATE_SALARY = 'extraction:update-salary',
  EXTRACTION_INVESTMENT = 'extraction:investment',
  EXTRACTION_BILLS = 'extraction:bill',
  EXTRACTION_TEF = 'extraction:tef',
  EXTRACTION_INCOME_REPORT = 'extraction:income-report',
  EXTRACTION_FINANCIAL_REPORT = 'extraction:financial-report',
  EXTRACTION_DEBT = 'extraction:debt',
  EXTRACTION_CONSOLIDATE = 'extraction:consolidate',
  EXTRACTION_PROPERTY_TAX = 'extraction:property-tax',
  EXTRACTION_PROPERTY_TAX_CLAVE_UNICA = 'extraction:property-tax-clave-unica',
  EXTRACTION_TAX_FOLDER = 'extraction:tax-folder',
  EXTRACTION_TAX_FOLDER_CLAVE_UNICA = 'extraction:tax-folder-clave-unica',
  EXTRACTION_TAX_FOLDER_PDF = 'extraction:tax-folder-pdf',
  EXTRACTION_TAX_FOLDER_PDF_CLAVE_UNICA = 'extraction:tax-folder-pdf-clave-unica',
  EXTRACTION_CONSUMER_LOAN = 'extraction:consumer-loan',
  EXTRACTION_MOVEMENT = 'extraction:movement',
  EXTRACTION_CREDIT_CARD_STATEMENT = 'extraction:credit-card-statement',
  EXTRACTION_TAX_REPORT = 'extraction:tax-report',
  EXTRACTION_INSURANCE = 'extraction:insurance',
  // settings only - TO DO: Fix this
  CLAVE_UNICA = 'clave-unica'
}

export const FlowTypes = { ...AssessmentFlowTypes, ...ExtractionFlowType };
export type FlowType = AssessmentFlowTypes | ExtractionFlowType;

export enum FlowStepComponent {
  HOME = 'home',
  ENTITIES = 'entities',
  CREDENTIALS = 'credentials',
  PROCESSING = 'processing',
  SUMMARY = 'summary',
  COMPANY_INFO = 'companyInfo',
  LEGAL_INFO = 'legalInfo',
  COMPANY_CONTACT_INFO = 'companyContactInfo',
  DASHBOARD = 'dashboard',
  ASSESSING = 'assessing'
}

export enum ProcessingStep {
  Connecting = 'connecting',
  Finding = 'finding',
  Success = 'success',
  Error = 'error'
}

export type ProgressStep = {
  step: ProcessingStep;
  nextStepPct?: number;
  image?: any;
  messageKey?: string;
};

export interface IFlowStep {
  page: FlowStepComponent;
}

export interface CredentialsFlowStep extends IFlowStep {
  page: FlowStepComponent.CREDENTIALS;
  actionType: string;
}

export interface ProcessingFlowStep extends IFlowStep {
  page: FlowStepComponent.PROCESSING;
  processingSteps: ProgressStep[];
}

export interface AssessingFlowStep extends IFlowStep {
  page: FlowStepComponent.ASSESSING;
  messageKey?: string;
  image?: any;
  assessmentArgs?: string[];
}

export type FlowStep = IFlowStep | CredentialsFlowStep | ProcessingFlowStep | AssessingFlowStep;

export enum EntityType {
  BANK = 'bank',
  ENTERPRISE_BANK = 'enterprise-bank',
  SERVICE = 'service',
  GOVERMENT = 'goverment',
  CRYPTO = 'crypto'
}

export interface EntityConfig {
  id: EntityId;
  name: string;
  description: string;
  siteUrl?: string; // We need to add URLs for all entities
  active?: boolean; // This value is provided by the API, so it's optional to await the value
  type: EntityType;
}

export enum EntityId {
  // personal banks
  BCI = 'banco-bci',
  CHILE = 'banco-chile',
  ESTADO = 'banco-estado',
  FALABELLA = 'banco-falabella',
  ITAU = 'banco-itau',
  SANTANDER = 'banco-santander',
  SCOTIABANK = 'banco-scotiabank',
  RIPLEY = 'banco-ripley',
  SECURITY = 'banco-security',
  CONSORCIO = 'banco-consorcio',
  BICE = 'banco-bice',
  //
  TENPO = 'tenpo',
  // enterprise banks
  BCI_EMPRESA = 'banco-bci-empresa',
  CHILE_EMPRESA = 'banco-chile-empresa',
  ESTADO_EMPRESA = 'banco-estado-empresa',
  // services
  SERVIPAG = 'servipag',
  // gov services
  SII = 'sii',
  CMF = 'cmf',
  AFC = 'afc',
  CLAVE_UNICA = 'clave-unica',
  // crypto
  BINANCE = 'binance',
  BUDA = 'buda',
  COINBASE = 'coinbase',
  // Sunat - Peru
  SUNAT = 'sunat',
  // demo
  NIUBANK = 'niubank'
}

export interface EntityInfo {
  name: string;
  id: string;
  enabled: boolean;
  actions: Record<string, unknown>[];
}

export interface SyncedAccount {
  entityId: EntityId;
  permissions: {
    action: string;
    origin: string;
    enabled: boolean;
  }[];
}

export interface PermissionHistory {
  entityId: EntityId;
  action: string;
  origin: string;
  datetime: string;
}

export interface PermissionHistoryGroup {
  date: string;
  data: PermissionHistory[];
}
