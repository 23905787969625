import { client } from '@/utils/apiClient';
import { WidgetServiceResponse, TaskResult, TaskStatus } from '@/@types';
import { AxiosError } from 'axios';

interface ResponseGetTask {
  taskId: string;
  message?: string;
}

interface taskErrorResponse {
  status: number;
  errorMessage: string;
}

interface TaskResponse {
  taskStatus: TaskStatus;
  taskStatusCode: number;
  results: TaskResult;
}

export const reqTask = async (
  password: string,
  action?: string
): Promise<WidgetServiceResponse> => {
  const response = await client.post('widget/tasks', { password, action });

  let isSuccess = false;
  let taskId = '';
  let message = '';
  const status = response.status;
  const data = response.data as ResponseGetTask;

  if (response.status === 200) {
    isSuccess = true;
    taskId = data.taskId;
  }

  if (data.message) {
    message = data.message;
  }

  return {
    taskId,
    isSuccess,
    status,
    message
  };
};

export const reqTaskResult = async (taskId: string): Promise<WidgetServiceResponse> => {
  const response = await client.get(`widget/tasks/${taskId}`);

  const taskResponse = response.data as TaskResponse;

  const data: WidgetServiceResponse = {
    isSuccess: false,
    taskFinished: false,
    status: response.status,
    taskStatus: taskResponse.taskStatus,
    taskStatusCode: taskResponse.taskStatusCode
  };

  if (data.taskStatus === TaskStatus.Success) {
    data.isSuccess = true;
    data.results = response.data.results as TaskResult;
  }

  if (!(data.taskStatus === TaskStatus.Pending || data.taskStatus === TaskStatus.Running)) {
    data.taskFinished = true;
  }

  return data;
};

export const getTaskErrorResponse = (error: AxiosError): taskErrorResponse => {
  const response = {
    status: error.response?.status || 500,
    errorMessage: ''
  };
  const data = error.response?.data as { message: string };
  if (data && data.message) {
    response.errorMessage = data.message;
  }
  return response;
};
