import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useFlowStore, useAuthStore } from '@/store';
import { ErrorScreenInformation } from '../ErrorScreen';

export default function FlowProvider({ children }: { children: React.ReactElement }) {
  const router = useRouter();
  const { getCurrentFlowStep, goPrevStep, dataLoaded, loadFlowSteps, getEntityList } =
    useFlowStore();
  const { isAuthenticated } = useAuthStore();

  const { error } = useFlowStore();
  const [currentContext, currentStep] = getCurrentFlowStep();

  useEffect(() => {
    window.onpopstate = () => {
      goPrevStep();
    };
  }, [goPrevStep]);

  useEffect(() => {
    if (isAuthenticated && !dataLoaded) {
      loadFlowSteps();
      getEntityList();
    }
  }, [isAuthenticated, loadFlowSteps, dataLoaded, getEntityList]);

  useEffect(() => {
    const newRoute = `/${currentContext}/${currentStep}`;

    if (currentContext && currentStep && newRoute !== router.route) {
      router.push(`/${currentContext}/${currentStep}`);
    }
  }, [currentContext, currentStep, router]);

  if (error) return <ErrorScreenInformation />;

  return <>{children}</>;
}
