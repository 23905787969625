export const TabsStyles = {
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    boufin: {
      tabpanel: {
        padding: '0px'
      },
      tablist: {
        width: '100%',
        justifyContent: 'space-between',
        margin: '0 auto'
      },
      tab: {
        color: '#726F86',
        fontWeight: '600',
        margin: 'auto',
        width: '100%',
        borderBottom: 'solid 2px #D9DFFF',
        _selected: {
          color: 'black',
          zIndex: '10',
          borderBottom: 'solid 2px #4261FF'
        }
      }
    }
  },
  // The default `size` or `variant` values
  defaultProps: {
    variant: 'boufin'
  }
};
