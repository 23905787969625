import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import {
  EntityConfig,
  EntityId,
  EntityType,
  PermissionHistoryGroup,
  SyncedAccount
} from '@/@types';
import { useFlowStore } from './flow';
import { syncedAccountsData, permissionHistoryData } from '../utils/syncedAccountsTest';

const settingsLoginEntity = {
  id: EntityId.CLAVE_UNICA,
  name: 'Clave Única',
  description: 'Clave Única',
  active: false,
  type: EntityType.GOVERMENT
};

const baseSteps = ['dashboard'];

const unloggedSteps = ['credentials', 'processing'];

interface State {
  isAuthenticated: boolean;
  settingSteps: string[];
  settingsLoginEntity: EntityConfig;
  syncedAccounts: SyncedAccount[];
  permissionsHistory: PermissionHistoryGroup[];
  loggin: () => void;
  loggout: () => void;
  setSettingsContext: (context: string) => void;
  switchEntityAction: (entityId: EntityId, action: string, state: boolean) => void;
  unsyncEntity: (entityId: EntityId) => void;
}

const initialStatus = {
  isAuthenticated: false,
  settingSteps: [...baseSteps, ...unloggedSteps],
  settingsLoginEntity,
  syncedAccounts: syncedAccountsData as SyncedAccount[],
  permissionsHistory: permissionHistoryData as PermissionHistoryGroup[]
};

export const useSettingsStore = create<State>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialStatus,
        loggin() {
          set({
            isAuthenticated: true
          });
        },
        loggout() {
          set({
            isAuthenticated: false,
            settingSteps: [...baseSteps, ...unloggedSteps]
          });
        },
        setSettingsContext(context) {
          switch (context) {
            case 'synced-accounts':
              set({
                settingSteps: [...baseSteps, context]
              });
              useFlowStore.getState().goToStep(context);
              return;
            case 'permission-history':
              set({
                settingSteps: [...baseSteps, context]
              });
              useFlowStore.getState().goToStep(context);
              return;
            default:
              return;
          }
        },
        switchEntityAction(entityId, action, state) {
          const syncedAccounts = [...get().syncedAccounts];
          const entity = syncedAccounts.find((acc) => acc.entityId === entityId);
          if (entity) {
            const permission = entity.permissions.find((p) => p.action === action);
            if (permission) {
              permission.enabled = state;
              set({
                syncedAccounts
              });
            }
          }
        },
        unsyncEntity(entityId) {
          set({
            syncedAccounts: get().syncedAccounts.filter((acc) => acc.entityId !== entityId)
          });
        }
      }),
      {
        name: 'settings'
      }
    )
  )
);
