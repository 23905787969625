export const InputStyles = {
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    boufin: {
      field: {
        border: '1px',
        borderColor: '#DBE4EF',
        height: '48px',
        borderRadius: '8px',
        _focus: {
          border: '2px',
          borderColor: '#338DFF',
          boxShadow: 'none'
        }
      }
    }
  },
  // The default `size` or `variant` values
  defaultProps: {
    variant: 'boufin'
  }
};
